import React from "react";
import {BrowserRouter ,Routes,Route } from 'react-router-dom';
const Course = React.lazy(()=>import('./Course'));
const Aboutus = React.lazy(()=>import('./Aboutus'));
 // eslint-disable-next-line
const Home = React.lazy(()=>import('./Home'));
const Contactus = React.lazy(()=>import('./Contactus'));  
const Router = () =>{
    return <BrowserRouter  basename="/">
       <Routes>
        <Route path = "/" exact  element={<Course />} />
        {/*<Route path="/" exact element={<Home />} />*/}
        <Route path = "/courses/:courseId" exact  element={<Course />}/>
        <Route path = "/aboutus" exact element={<Aboutus />} />
        <Route path = "/contactus" exact element={<Contactus />} />
   
       </Routes>
    </BrowserRouter>

};
export default React.memo(Router);
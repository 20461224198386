import React, { useEffect,useRef } from "react";
import M from "materialize-css";
import EnquiryForm from './EnquiryForm';
const ContactusModal = (props) =>{
    const instances = useRef();
    useEffect(()=>{
        var elems = document.querySelectorAll('#enquiry-modal');
        instances.current = M.Modal.init(elems[0], {
          startingTop: "50%",
          onCloseStart: () => { 
            props.closeEnquiry();
          },
      });
      if(instances.current){
        if(props.open){
          instances.current.open();
        }else{
          instances.current.close();
        }
      }
    
    },[props,props.open]);
    return <div id="enquiry-modal" className="modal">
    <div className="modal-content">
      <h4>Request a Call Back</h4>
      <div className="sub-title">Thanks for your interest in our service.We will get in touch with you shortly.</div>
      <div className="section white">
      <div className="row">
      <div className="col s12 ms12 l12">
        <EnquiryForm modal={true} closeEnquiry={props.closeEnquiry} />
      </div>
      </div>
      </div>
      
    </div>
  </div>
}
export default React.memo(ContactusModal);
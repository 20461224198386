import axios from "axios";
const NETWORK_TIMEOUT = 30000;

const AxiosInstance = axios.create({
    baseURL:  "",
    headers: {
        "Content-Type": "application/x-www-form-urlencoded",
    },
    timeout: NETWORK_TIMEOUT,
});

export default AxiosInstance;
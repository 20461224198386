import React,{useEffect,useState} from "react";
import { numbersOnly, charachtersOnly, alphaNumeric, checkValidity } from '../utility/helper';
import axios from '../AxiosInstance';
import qs from "query-string";

const EnquiryForm = (props) =>{
    const contactForm = {
        fullName: {
            value: "",
            validation: {
                required: true,
                minLength: 3,
                maxLength: 10,
                charsOnly: true
            },
            valid: false,
            touched: false,
            showError: false,
            error: null,
            placeholder: "Name"
        },
        emailID: {
            value: "",
            validation: {
                required: true,
                minLength: 3,
                maxLength: 150,
                isemail: true,
            },
            valid: false,
            touched: false,
            showError: false,
            error: null,
            placeholder: "Email Address"
        },
        mobileNo: {
            value: "",
            validation: {
                required: true,
                minLength: 10,
                maxLength: 10,
                ismobile: true,
                numbersOnly: true,
            },
            valid: false,
            touched: false,
            showError: false,
            error: null,
            placeholder: "Phone Number"
        },
        message: {
            value: "",
            validation: {
                required: true,
                minLength: 10,
                maxLength: 256,
                aplaNumericOnly: true,
            },
            valid: false,
            touched: false,
            showError: false,
            error: null,
            placeholder: "Message"
        }
    };
    const [contatusForm, setContactusForm] = useState(JSON.parse(JSON.stringify(contactForm)));
    const [message,setMessage] = useState(null);
    const [status,setStatus] = useState(null);
    // eslint-disable-next-line 
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(()=>{
        if(message){
            setTimeout(()=>{
                setMessage(null);
                setStatus(null);
            },1000)
        }
    },[message]);

    const inputHandler = (event, inputIdentifier) => {
        const updatedContactFrom = {
            ...contatusForm
        };
        const updateFormElement = {
            ...updatedContactFrom[inputIdentifier],
        };
      
        let value = event.target.value;
        if (updateFormElement.validation.numbersOnly) {
            let isValid = numbersOnly(value);
            if (!isValid) {
                updateFormElement.error = `Enter only numbers for the ${updateFormElement.placeholder}`;
            }
            updateFormElement.value = isValid === true ? value : value.substring(0, value.length - 1);

        } else if (updateFormElement.validation.charsOnly) {
            let isValid = charachtersOnly(value);
            if (!isValid) {
                updateFormElement.error = `Enter only alphabetical characters for the ${updateFormElement.placeholder}`;
            }
            updateFormElement.value = isValid === true ? value : value.substring(0, value.length - 1);
        } else if (updateFormElement.validation.aplaNumericOnly) {
            let isValid = alphaNumeric(value);
            if (!isValid) {
                updateFormElement.error = `Enter only alphanumeric  characters for the ${updateFormElement.placeholder}`;
            }
            updateFormElement.value = isValid === true ? value : value.substring(0, value.length - 1);
        } else {
            updateFormElement.value = value;
        }
        updateFormElement.touched = true;
        updateFormElement.showError = false;
        let validate = checkValidity(updateFormElement.value, updateFormElement.validation);
        
        let isElemValid = validate.status;
        if (isElemValid) {
            updateFormElement.error = null;
        }else if(validate.error){
            updateFormElement.error = updateFormElement.placeholder+" "+validate.error;
        }
        updateFormElement.valid = isElemValid;
        updatedContactFrom[inputIdentifier] = updateFormElement;
        let isFormValid = contactForm.fullName.valid && contactForm.lastName.valid && contactForm.emailID.valid && contactForm.mobileNo.valid && contactForm.message.valid;
        setContactusForm(updatedContactFrom);
        setIsFormValid(isFormValid);
    }
    const validateForm = () => {
        if (!isFormValid) {
            let updatedContactFrom = {
                ...contatusForm
            };
            for (let inputIdentifier in updatedContactFrom) {
                let updateFormElement = {
                    ...updatedContactFrom[inputIdentifier],
                };

                if (updateFormElement.valid === false) {
                    updateFormElement.showError = true;
                    updatedContactFrom[inputIdentifier] = updateFormElement;
                    if (!updateFormElement.error) {
                        updateFormElement.error = `Please enter ${updateFormElement.placeholder}`;
                    }
                    setContactusForm(updatedContactFrom);
                    return false;
                };
            }
        };
        return true;
    }
    const writeToSheet = async (contactusInfo) => {
        console.log(contactusInfo);
        axios.post("/api/sendContactInfo", qs.stringify(contactusInfo))
        .then((response) => {
            console.log(response);
            if(response.data.status.toLowerCase() === "success"){
                setContactusForm(contactForm);      
                if(props.closeEnquiry){
                    props.closeEnquiry();
                }          
            }
            setMessage(response.data.message);
            setStatus(response.data.status);          
        })
        .catch((error) => {
           
        });
      };
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(validateForm());
        if (!validateForm()) return;
    
        let contactusInfo = {
            fullName:contatusForm.fullName.value,
            emailID:contatusForm.emailID.value,
            mobileNo:contatusForm.mobileNo.value,
            message:contatusForm.message.value
        };
        writeToSheet(contactusInfo);
        return false;
    }
    return   <form onSubmit={(e) => handleSubmit(e)}>
    <div className="row">
        <div className="input-field col s12 ms12 l12">
            <input id="first_name" type="text" value={contatusForm.fullName.value} name="first_name" onChange={(event) => inputHandler(event, "fullName")} maxLength={contatusForm.fullName.validation.maxLength} minLength={contatusForm.fullName.validation.minLength} autoComplete="off" />
            <label htmlFor="first_name" className="">Name</label>
            {contatusForm.fullName.showError && <span className="error">{contatusForm.fullName.error}</span>}
        </div>
        
    </div>
    <div className="row">
        <div className="input-field col s12 ms12 l12">
            <input id="email" type="text" value={contatusForm.emailID.value} onChange={(event) => inputHandler(event, "emailID")} autoComplete="off" maxLength={contatusForm.emailID.validation.maxLength} minLength={contatusForm.emailID.validation.minLength} />
            <label htmlFor="email" className="">Email Address</label>
            {contatusForm.emailID.showError && <span className="error">{contatusForm.emailID.error}</span>}
        </div>
    </div>
    <div className="row">
        <div className="input-field col s12 ms12 l12">
            <input id="phone" type="text" value={contatusForm.mobileNo.value} onChange={(event) => inputHandler(event, "mobileNo")} autoComplete="off" maxLength={contatusForm.mobileNo.validation.maxLength} minLength={contatusForm.mobileNo.validation.minLength} />
            <label htmlFor="phone" className=""  >Phone Number</label>
            {contatusForm.mobileNo.showError && <span className="error">{contatusForm.emailID.error}</span>}
        </div>
    </div>
    <div className="row">
        <div className=" col s12 ms12 l12">
            <div className="input-field" style={{marginBottom:"0px"}}>
                <textarea id="message" value={contatusForm.message.value} rows="6" cols="100" className="materialize-textarea" onChange={(event) => inputHandler(event, "message")} maxLength={contatusForm.message.validation.maxLength} minLength={contatusForm.message.validation.minLength}></textarea>
                <label htmlFor="message" className="">Message</label>
                {contatusForm.message.showError && <span className="error">{contatusForm.message.error}</span>}
            </div>
            <span className="grey-text">{contatusForm.message.value.length+"/"+contatusForm.message.validation.maxLength}</span>
        </div>
       
    </div>
    {status &&  <div className="row">
        <div className="col s12 ms12 l12 center">
            <span className={status === "success" ? "success":"error"}>{message}</span>
        </div>
    </div>}
    <div className="row">
        <div className="col s12 ms12 l12">
            <button type="submit" className="secondary-btn active">Get Call Back</button>
        </div>
    </div>

</form>


}
export default React.memo(EnquiryForm);
import React, { useEffect, useState } from "react";
import M from "materialize-css";
import ContactusModal from "./ContactusModal";
import Logo from '../assets/images/logo.jpeg';
//import gplay from '../assets/images/gplay.png';
//import GoogleOauthLogin from './auth/GoogleOauthLogin';
import CourseMenuItems from '../resources/CourseMenuItems.json';

const Header = (props) => {
  const [openEnquiry, setOpenEnquiry] = useState(false);
  useEffect(() => {

    let elems1 = document.querySelectorAll('.sidenav');
    M.Sidenav.init(elems1, {});

    var elems2 = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems2, {});
    var elems3 = document.querySelectorAll('.dropdown-trigger');
    M.Dropdown.init(elems3, {
      coverTrigger: false,
      constrainWidth: false
    });

    // initializeGoogleOneTapLogin();

    // eslint-disable-next-line
  }, [props]);
  // eslint-disable-next-line
  const initializeGoogleOneTapLogin = () => {
    console.log(window.google);
    if (window.google) {
      const googleOneTapCallback = (response) => {
        console.log(response);
      }
      window.google.accounts.id.initialize({
        client_id: "737074839120-gaamg6cqis6ff1e6s6lrgv3qeodo14g0.apps.googleusercontent.com",
        callback: googleOneTapCallback
      });


      window.google.accounts.id.prompt();


    }
  }
  const openEnquiryForm = () => {
    setOpenEnquiry(true);
  }
  const closeEnquiryForm = () => {
    console.log("closeEnquiryForm");
    setOpenEnquiry(false);
  }
  return <>

    <nav className="navbar nav-extended dark navbar-solid-transition">

      <div className="nav-wrapper">
        <div className="header-strip">
          <ul >
            <li className="call"><a href="#!" onClick={() => openEnquiryForm()}>
              <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" /></svg>
              &nbsp;&nbsp;&nbsp;Request a Call Back
            </a>
            </li>
            <li><a href="mailto:team@competeminds.com">team@competeminds.com</a></li>
          </ul>
        </div>
        <div>
          <a href="/courses/1" className="brand-logo">
            <img src={Logo} alt={"competeminds"} />
          </a>

          <ul id="nav-mobile" className="left hide-on-med-and-down">
            <li>
              <a href="#!" className="dropdown-trigger" data-target="pages">Courses<i
                className="material-icons right">arrow_drop_down</i></a>
            </li>

            <li><a href="/aboutus">About Us</a></li>
            {/*<li><a href="/contactus">Contact Us</a></li>*/}

          </ul>
          <ul id="nav-mobile" className="right hide-on-med-and-down">
            {/*<li>
            <GoogleOauthLogin><span>Login</span></GoogleOauthLogin>
          </li>*/}
            <li>
              <a href="https://play.google.com/store/apps/details?id=co.jack.nabcv" target="__blank">
                <span className="secondary-btn active">Download App</span>
              </a>
            </li>
            <li>
              <a href="/contactus">
                <span className="secondary-btn active">Contact Us</span>
              </a>
            </li>
          </ul>
          {CourseMenuItems && <ul id="pages" className="dropdown-content">
            {CourseMenuItems.map((value, index) => {
              return <li key={index}><a href={value.uri}>{value.title}</a></li>
            })}
          </ul>}

          <a href="#!" data-target="slide-out" className="sidenav-trigger button-collapse right"><i
            className="material-icons black-text">menu</i></a>
        </div>
      </div>


    </nav>
    <ul id="slide-out" className="sidenav">
      <li className="brand-logo">
        <img src={Logo} alt={"competeminds"} />
      </li>

      <li className="no-padding">
        <ul className="collapsible collapsible-accordion">
          <li className="bold">
            <a href="#!" className="collapsible-header waves-effect waves-teal active">Courses</a>
            <div className="collapsible-body">
              {CourseMenuItems && <ul>
                {CourseMenuItems.map((value, index) => {
                  return <li key={index}><a href={value.uri}>{value.title}</a></li>
                })}

              </ul>}
            </div>
          </li>
        </ul>
      </li>
      <li><a className="waves-effect waves-teal" href="/aboutus">About Us</a></li>
      <li><a className="waves-effect waves-teal" href="/contactus">Contact Us</a></li>
    </ul>
    <ContactusModal open={openEnquiry} closeEnquiry={closeEnquiryForm} />
    <div className="phone-in-talk" onClick={() => openEnquiryForm()}>
      <svg fill="#000000" width="800px" height="800px" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
        <path d="M231.55566,175.0791A56.07032,56.07032,0,0,1,176,224C96.59766,224,32,159.40234,32,80A56.07029,56.07029,0,0,1,80.91992,24.44434a16.02839,16.02839,0,0,1,16.65235,9.583l20.09179,46.87793a15.96924,15.96924,0,0,1-1.32031,15.06641L99.709,121.38965l-.00195.00195a76.54083,76.54083,0,0,0,35.20508,35.04981l25.043-16.69336a15.95139,15.95139,0,0,1,15.17871-1.39453l46.83789,20.07324A16.03476,16.03476,0,0,1,231.55566,175.0791ZM157.35156,47.72852a72.12078,72.12078,0,0,1,50.91992,50.91992,7.99975,7.99975,0,0,0,15.457-4.13086,88.16381,88.16381,0,0,0-62.2461-62.2461,7.99975,7.99975,0,0,0-4.13086,15.457Zm-8.28418,30.917a40.06626,40.06626,0,0,1,28.28711,28.28711,7.99975,7.99975,0,0,0,15.457-4.13086,56.10589,56.10589,0,0,0-39.61328-39.61328,7.99975,7.99975,0,0,0-4.13086,15.457Z" />
      </svg>
    </div>

  </>

}
export default React.memo(Header);
export const isValidateEmail = (emailaddress) => {
    // eslint-disable-next-line
    var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
    if (!emailReg.test(emailaddress)) {
        return false;
    }
    return true;
};
export const isValidMobileNo = (mobileNo) => {
    let startNo = mobileNo.substring(0, 1);
    if (
        startNo !== "9" &&
        startNo !== "8" &&
        startNo !== "7" &&
        startNo.substring(0, 1) !== "6"
    ) {
        return false;
    }
    if (mobileNo.length < 10 || mobileNo.length > 10) {
        return false;
    }
    return true;
};
export const charachtersOnly = (value) => {
    let charReg = /^[a-zA-Z\s]*$/;
    if (!charReg.test(value)) {
        return false;
    }
    return true;
};
export const numbersOnly = (value) => {
    let reg = /^[0-9]*$/;
    if (!reg.test(value)) {
        return false;
    }
    return true;
};
export const alphaNumeric = (value) => {
    let reg = /^[a-zA-Z0-9\s]+$/;
    if (!reg.test(value)) {
        return false;
    }
    return true;
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    let error = null;
    if (rules.required) {
        isValid = value.toString().trim() !== "" && isValid;
    }
    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
        if(!isValid && !error){
            error = `must be at least ${rules.minLength} characters`;
        }
    }
    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
        if(!isValid && !error){
            error = ` must be at most ${rules.minLength} characters`;
        }
    }
    if (rules.isemail === true) {
        isValid = isValidateEmail(value) && isValid;
        if(!isValid && !error){
            error = `is not valid`;
        }
    }
    if (rules.ismobile === true) {
        isValid = isValidMobileNo(value) && isValid;
        if(!isValid && !error){
            error = `is not valid`;
        }
    }
    if (rules.charOnly) {
        isValid = charachtersOnly(value) && isValid;
    }
    if (rules.numbersOnly) {
        isValid = numbersOnly(value) && isValid;
    }
    if (rules.alphaNumeric) {
        isValid = alphaNumeric(value) && isValid;
    }
    
    if (rules.maxValue && value !== "") {
        isValid = parseInt(value) <= parseInt(rules.maxValue) && isValid;
    }
    if (rules.regex) {
        const regex = new RegExp(rules.regex);
        isValid = regex.test(value);
    }

    return {status:isValid,error:error};
};